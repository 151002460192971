import { Slot, Slottable } from '@radix-ui/react-slot'

import * as React from 'react'
import { Spinner } from '../Spinner'
import { classNames } from '~/utils/class-names'

export const styles = {
  base: [
    'inline-flex justify-center items-center whitespace-nowrap rounded-lg border border-transparent px-4 py-2 font-semibold focus:outline-none focus:ring-4 text-center',
    'disabled:bg-gray-100 disabled:text-zinc-400 disabled:cursor-not-allowed dark:disabled:bg-zinc-700 dark:disabled:border-border-secondary dark:disabled:opacity-50',
  ],
  variant: {
    solid: 'bg-brand-600 text-white hover:bg-brand-700 focus:ring-brand-200',
    light:
      'bg-brand-100/60 text-brand-700 hover:bg-brand-200 focus:ring-brand-200 dark:bg-brand-400/30 dark:text-white',
    default:
      'border border-border-primary bg-surface-primary text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-brand-200 dark:text-zinc-300 dark:hover:bg-zinc-900',
    success: 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-200',
    warn: 'bg-amber-500 text-white hover:bg-amber-600 focus:ring-amber-200',
    danger: 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-200',
    lightDanger:
      'bg-red-50 text-red-700 hover:bg-red-200 hover:opacity-80 focus:ring-red-200 dark:bg-red-700 dark:text-red-50',
    outline:
      'bg-transparent text-brand-700 !border-brand-700 hover:bg-brand-50 focus:ring-brand-200',
    unstyled: 'bg-transparent text-base py-0',
  },
  size: {
    xs: 'rounded text-xs px-2.5 py-1.5',
    sm: 'px-3 py-[9px] text-sm',
    md: 'px-5 py-2 text-base',
    lg: 'px-6 py-3 text-md',
  },
  isLoading: 'opacity-50',
  isFull: 'w-full',
  isUppercase: 'uppercase',
}

export type ButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  variant?: keyof typeof styles.variant
  size?: keyof typeof styles.size
  isFull?: boolean
  isUppercase?: boolean
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  isLoading?: boolean
  loadingText?: string
  asChild?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const {
    asChild,
    children,
    className,
    size = 'md',
    variant = 'solid',
    disabled = false,
    leftIcon,
    rightIcon,
    isFull,
    isUppercase,
    isLoading,
    loadingText,
    type = 'button',
    ...rest
  } = props

  const Component = (asChild ? Slot : 'button') as 'button'

  const classes = classNames(
    styles.base,
    styles.size[size],
    styles.variant[variant],
    {
      [styles.isLoading]: isLoading,
      [styles.isFull]: isFull,
      [styles.isUppercase]: isUppercase,
    },
    className,
  )

  return (
    <Component
      {...rest}
      className={classes}
      disabled={disabled || isLoading}
      ref={forwardedRef}
      type={type}
    >
      {leftIcon && !isLoading && (
        <span aria-hidden="true" className="mr-2">
          {leftIcon}
        </span>
      )}

      {isLoading ? (
        <>
          <Spinner className="-ml-1.5" />
          {loadingText || <Slottable>{children}</Slottable>}
        </>
      ) : (
        <Slottable>{children}</Slottable>
      )}

      {rightIcon && !isLoading && (
        <span aria-hidden="true" className="ml-2">
          {rightIcon}
        </span>
      )}
    </Component>
  )
})

Button.displayName = 'Button'
