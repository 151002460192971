type SpinnerProps = JSX.IntrinsicElements['svg']

export function Spinner(props: SpinnerProps) {
  return (
    <svg
      height="30"
      version="1.1"
      viewBox="0 0 100 100"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        fill="currentColor"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="800ms"
          from="0 50 50"
          repeatCount="indefinite"
          to="360 50 50"
          type="rotate"
        />
      </path>
    </svg>
  )
}
